import { HTMLAttributes, lazy } from 'react';
import './styles/NavbarAdditionalSection.scss';
import cn from 'classnames';
import { TopMenuModel } from '../../../../types/dto/MenuModel';
import NavbarDropBlock, { NavbarDropBlockMode } from '../common/NavbarDropBlock';
import NavbarToggler from '../common/NavbarToggler';
import { useVisibility } from '../hooks/useVisibility';
import { useCookies } from 'react-cookie';
import WithRtl from '../../../../shared/WithRtl';
const RTL = lazy(() => import('./NavbarAdditionalSectionRtl'));

const NavbarAdditionalSection = ({
  className,
  items = [],
  mode = 'popup',
  visibility,
  isRoot = true
}: HTMLAttributes<HTMLDivElement> & {
  items?: TopMenuModel[];
  mode?: NavbarDropBlockMode;
  visibility: ReturnType<typeof useVisibility>;
  isRoot?: boolean;
}) => {
  const [cookies] = useCookies(['country', 'countryName']);
  const virtualLink = (link: string, type: string) => {
    switch (type) {
      case 'ContactUs': {
        return `${link}/${cookies.country}/${cookies.countryName}`;
      }
      default:
        return link;
    }
  };
  const { openElem, toggle } = visibility;
  return (
    <>
      <WithRtl lazyElement={RTL} />
      <nav
        className={cn('navbar-v2-additional', className, {
          'navbar-v2-additional-not-root': !isRoot
        })}>
        {items
          ?.filter((i) => i.menuType !== 'login')
          ?.map((i, index) => {
            const href = virtualLink(i.url, i.menuType);
            if (!i.subMenu) {
              return (
                <a
                  key={'top_menu_item_' + i.title}
                  className={cn('navbar-v2-additional-link', className, {
                    'navbar-v2-additional-link-not-root': !isRoot
                  })}
                  href={href}>
                  {i.title}
                </a>
              );
            }
            const elementkey = i.title + '_additional_section';
            return (
              <div key={'top_menu_item_wrapper_' + i.title}>
                <div
                  className="navbar-v2-additional-item"
                  key={'top_menu_item_' + i.title}
                  onClick={() => toggle(elementkey)}
                  onKeyUp={(e) => e.code == 'Enter' && toggle(elementkey)}>
                  <NavbarToggler
                    isOpen={openElem === elementkey}
                    ariaExpanded={openElem === elementkey}
                    isRootDynamic={isRoot}>
                    <a
                      href={i && i?.subMenu?.length ? null : href}
                      className={cn('navbar-v2-additional-link', className, {
                        'navbar-v2-additional-link-not-root': !isRoot
                      })}>
                      {i.title}
                    </a>
                  </NavbarToggler>
                </div>
                <NavbarDropBlock
                  isOpen={openElem === elementkey}
                  mode={mode}
                  left={false}
                  right={false}>
                  {i.subMenu?.map((subItem) => {
                    return (
                      <a
                        key={'top_menu_item_' + subItem.title}
                        href={subItem.url}
                        className="navbar-v2-additional-link inner">
                        {subItem.title}
                      </a>
                    );
                  })}
                </NavbarDropBlock>
              </div>
            );
          })}
      </nav>
    </>
  );
};

export default NavbarAdditionalSection;
