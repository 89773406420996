import { useCookies } from 'react-cookie';
import { CountryModel, GeoLocation, LanguageModel } from '../../../../types/dto/MenuModel';
import { HTMLAttributes, useMemo } from 'react';
import { dynamic } from '../../../../environments/environmentParsing';
import './styles/NavbarLanguageControl.scss';
import NavbarToggler from '../common/NavbarToggler';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

const NavbarLanguageControl = ({
  geoLocation,
  isOpen,
  onClick,
  isRoot = true
}: {
  geoLocation?: GeoLocation;
  isOpen: boolean;
  isRoot?: boolean;
} & HTMLAttributes<HTMLDivElement>) => {
  const [{ language, country }] = useCookies(['language', 'country']);
  const userCountry = useMemo(
    () => country ?? geoLocation?.countryIsoCode,
    [country, geoLocation?.countryIsoCode]
  );

  const { t } = useTranslation();

  const translations = {
    flagAlt: t('accessibility.FlagAlt'),
    ariaFlagButton: t('accessibility.AriaFlagButton')
  };

  const userLanguage = useMemo(() => language?.split('-')?.[0], [language]);

  return (
    <NavbarToggler
      isOpen={isOpen}
      className="navbar-v2-country-language"
      onClick={onClick}
      isRootDynamic={isRoot}>
      <div
        className={cn('navbar-v2-country-wrapper', {
          'navbar-v2-country-wrapper-not-root': !isRoot
        })}
        role="button"
        aria-expanded={isOpen}
        tabIndex={0}
        aria-label={translations.ariaFlagButton}>
        <img
          className="navbar-v2-country-flag"
          width="42"
          height="42"
          src={`${dynamic['REACT_APP_FLAGS_LOCATION']}/${userCountry}.svg`}
          alt={translations.flagAlt.replace('countryName', geoLocation?.countryName)}
        />
      </div>
      <div
        className={cn('navbar-v2-language', {
          'navbar-v2-language-not-root': !isRoot
        })}>
        {userLanguage}
      </div>
    </NavbarToggler>
  );
};

export default NavbarLanguageControl;
