import { FormEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useReusableContent } from '../../hooks/useReusableContent';
import { Scope } from '../../types/dto/Scope';
import { ZimSearchResult, ZimSearchTag } from '../../types/dto/ZimSearch';
import { appHelper } from '../../helpers/appHelper';
import { useTranslation } from 'react-i18next';
import { BindedSearchHandler, BindedTryValidate } from '../../hooks/useBindedQueryParams';
import { stringExtensions } from '../../helpers/stringExtensions';
import { translationService } from '../../services/translationsService';
import { SelectItem } from '../../types/shared/select';
import { StringToObject } from '../../hooks/useQueryParams';
import { zimSearchService } from '../../services/zimSearch';
import { apiRequests } from '../ContactUsV2/mock';

export type ZimSeachQuery = 'page' | 'q' | 'take' | 'sortDir' | 'sortBy' | 'tags';

export type ZimSearchProps = {
  takeCount?: number;
  minCharacters?: number;
  maxCharacters?: number;
};
type ZimSearchSortItems = (SelectItem<string> & {
  hideDirection?: boolean;
})[];

export const useZimSearch = ({
  takeCount,
  minCharacters = 20,
  maxCharacters = 100
}: ZimSearchProps) => {
  const prevSearchedQuery = useRef<string | null>(null);
  const { getReusableContent } = useReusableContent();
  const [scope, setScope] = useState<Scope<ZimSearchResult>>(appHelper.getDefaultScope);

  const { t } = useTranslation();
  const isInitial = useRef(true);
  const [inputValidationError, setInputValidationError] = useState('');
  const [searchIsDisabled, setSearchIsDisabled] = useState(false);
  const [query, setQuery] = useState('');
  const [searchedTags, setSearchedTags] = useState<string[]>([]);
  const clearSearchedTags = () => setSearchedTags([]);

  const translations = useMemo(() => {
    return {
      commonError: t('common'),
      loadMore: t('searchPage.LoadMore'),
      allResults: t('searchPage.AllResults'),
      popularSearch: t('searchPage.PopularSearch'),
      searchLabel: t('searchPage.SearchLabel'),
      clearSearch: t('searchPage.ClearSearch'),
      searchButton: t('searchPage.SearchButton'),
      next: t('searchPage.Next'),
      previous: t('searchPage.Previous'),
      sortLabel: t('searchPage.SortLabel'),
      noResults: t('searchPage.NoResults'),
      minLengthError: t('searchPage.MinLengthError'),
      maxLengthError: t('searchPage.MaxLengthError'),
      totalResults: t('searchPage.TotalResults', {
        replace: {
          total: scope?.result?.total ?? 0,
          query: query
        }
      })
    };
  }, [t, scope?.result?.total, query]);

  const clear = () => {
    setScope(appHelper.getDefaultScope);
    setQuery('');
  };

  const tryValidate = useCallback<BindedTryValidate<ZimSeachQuery>>(
    ({ page, q, take, sortBy, sortDir }) => {
      const hasEnoughCharacters = minCharacters ? q?.length >= minCharacters : true;
      const tooManyCharacters = maxCharacters <= q?.length;

      if (!hasEnoughCharacters) {
        setInputValidationError(
          t('searchPage.MinLengthError', {
            replace: {
              minLength: minCharacters
            }
          })
        );
      }

      if (tooManyCharacters) {
        setInputValidationError(
          t('searchPage.MaxLengthError', {
            replace: {
              maxLength: maxCharacters
            }
          })
        );
      }
      return !stringExtensions.isEmptyOrSpaces(q) && hasEnoughCharacters && !tooManyCharacters;
    },
    []
  );

  const fetchResultHandler = useCallback<BindedSearchHandler<ZimSeachQuery>>(
    async ({ page, q, take, sortBy, sortDir, tags }) => {
      setSearchIsDisabled(true);
      setInputValidationError('');
      setScope((prev) => {
        return { ...prev, isLoading: true };
      });
      let errorMessage = '';
      let result: ZimSearchResult = null;

      if (prevSearchedQuery.current?.toLowerCase() !== q?.toLowerCase()) {
        clearSearchedTags();
      }
      try {
        const apiResult = await zimSearchService.search(
          q,
          page ?? '1',
          take ?? takeCount.toString(),
          sortBy ?? '',
          sortDir ?? '',
          tags ?? ''
        );
        result = apiResult;
        if (apiResult.tags?.length) {
          setSearchedTags(apiResult.tags);
        }

        setQuery(q);
        prevSearchedQuery.current = q;
      } catch (error) {
        errorMessage = `${translations.commonError}`;
      }

      setScope((prev) => {
        return {
          ...prev,
          isLoading: false,
          errorMessage,
          result,
          isResponse: true
        };
      });
      setSearchIsDisabled(false);
      isInitial.current = false;
    },
    []
  );

  const localizedLinks = useMemo(() => {
    const language = translationService.getLanguage();
    const escapedLanguageSegment = language === 'en' ? '' : '/' + language;
    return scope.result?.items?.map((r) => ({
      ...r,
      url: r.isStatic ? r.url : `${escapedLanguageSegment}${r.url}`
    }));
  }, [scope.result?.items]);

  const sortItems = useMemo<ZimSearchSortItems>(() => {
    return [
      {
        key: '',
        name: 'Relevance',
        hideDirection: true
      },
      {
        key: 'date_updated',
        name: 'Date'
      }
    ];
  }, [t]);

  return {
    isInitial,
    localizedLinks,
    getReusableContent,
    inputValidationError,
    translations,
    searchIsDisabled,
    sortItems,
    fetchResultHandler,
    t,
    tryValidate,
    scope,
    clear,
    searchedTags
  };
};

export const useZimSearchPagination = (
  searchParams: StringToObject<ZimSeachQuery>,
  sortItems: ZimSearchSortItems,
  scope: Scope<ZimSearchResult>,
  takeCount?: number
) => {
  const takeMemo = useMemo(() => {
    if (searchParams.take) {
      return parseInt(searchParams.take);
    }

    return takeCount;
  }, [searchParams.take, takeCount]);
  const pageMemo = useMemo(() => {
    if (searchParams.page) {
      return parseInt(searchParams.page) - 1;
    }

    return 0;
  }, [searchParams.page]);
  const pagesNumber = useMemo(() => {
    if (!scope.result) {
      return 0;
    }

    const pages = Math.ceil(scope.result.total / takeMemo);
    return pages === 1 ? 0 : pages;
  }, [scope, takeMemo]);

  const selectedSortItem = useMemo<SelectItem<string>>(() => {
    return (
      sortItems.find((s) => s.key === searchParams.sortBy) ?? sortItems.find((s) => s.key === '')
    );
  }, [searchParams.sortBy, sortItems]);

  const sortIsAscending = useMemo(() => {
    return searchParams.sortDir === 'asc';
  }, [searchParams.sortDir]);

  return {
    takeMemo,
    pageMemo,
    pagesNumber,
    selectedSortItem,
    sortIsAscending
  };
};
