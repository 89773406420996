import { useEffect, FormEventHandler, useRef, useState, useMemo, ChangeEventHandler } from 'react';
import { ZimSeachQuery, ZimSearchProps, useZimSearch } from '../../../ZimSearch/useZimSearch';
import { StringToObject } from '../../../../hooks/useQueryParams';
import './styles/NavbarSearch.scss';
import { routeHelper } from '../../../../helpers/routeHelper';
import { useMessageBus } from '../../../../hooks/useMessageBus';
import { faX } from '@fortawesome/free-solid-svg-icons';
import searchIcon from '../../../../assets/images/icon-new-search-dark.svg';

import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ZimPopularSearch, ZimSearchTag } from '../../../../types/dto/ZimSearch';

const NavbarSearch = ({
  popularSearches,
  takeCount = 6,
  maxCharacters = 100,
  minCharacters = 2
}: { popularSearches?: ZimPopularSearch[] } & ZimSearchProps) => {
  const inputRef = useRef<HTMLInputElement>();
  const page = useRef(1);
  const [isEmpty, setIsEmpty] = useState(true);
  const onType: ChangeEventHandler<HTMLInputElement> = (e) => {
    setIsEmpty((_) => !e.target?.value);
  };
  const [pickedTags, setPickedTags] = useState<string[]>([]);

  const {
    isInitial,
    localizedLinks,
    getReusableContent,
    inputValidationError,
    translations,
    searchIsDisabled,
    sortItems,
    fetchResultHandler,
    tryValidate,
    scope,
    clear,
    searchedTags: tags
  } = useZimSearch({
    takeCount,
    maxCharacters,
    minCharacters
  });
  const viewCount = useMemo(() => {
    if (!scope?.result?.total) return 0;
    const viewed = page.current * takeCount;
    return viewed > scope.result.total ? scope.result.total : viewed;
  }, [scope?.result, takeCount]);

  const clearSearch = () => {
    clear();
    inputRef.current.value = '';
    inputRef.current.focus();
    setIsEmpty(true);
  };
  const pickTag = (tag: string) => {
    setPickedTags(() => [tag]);
  };
  const clearTags = () => {
    setPickedTags([]);
  };
  const trySendRequest = async (q: string) => {
    const request: StringToObject<ZimSeachQuery> = {
      q,
      page: page.current.toString(),
      take: takeCount.toString(),
      sortBy: '',
      sortDir: '',
      tags: pickedTags?.join(',')
    };

    if (!tryValidate(request)) {
      return;
    }

    await fetchResultHandler(request);
  };

  useEffect(() => {
    if (scope?.isLoading) return;
    if (!scope.result?.total) {
      clearTags();
    }
  }, [scope?.result?.total, scope?.isLoading]);
  const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    const query = inputRef.current.value;
    page.current = 1;
    await trySendRequest(query);
  };

  const onLoadMore = async () => {
    page.current++;
    await trySendRequest(inputRef.current.value);
  };

  const queryLinkUrl = (query?: string) => {
    return routeHelper.globalSearchUrl(query ?? inputRef.current.value);
  };

  return (
    <div className="navbar-v2-search">
      <form onSubmit={onSubmit}>
        <div
          className={cn('navbar-v2-search-input-group', {
            'search-input-group-typed': !isEmpty
          })}>
          {!isEmpty && (
            <button className="navbar-v2-search-icon" type="submit">
              <img src={searchIcon} alt="search-icon" width={30} height={30} />
            </button>
          )}
          <input ref={inputRef} placeholder={translations.searchLabel} onChange={onType} />
          {!isEmpty && (
            <button
              className="navbar-v2-search-clear navbar-v2-search-icon search-icon-right"
              aria-label={translations.clearSearch}
              onClick={clearSearch}>
              <FontAwesomeIcon icon={faX} width={30} height={30} />
            </button>
          )}
          {isEmpty && (
            <img
              src={searchIcon}
              className="navbar-v2-search-icon search-icon-right"
              alt="search-icon"
              width={30}
              height={30}
            />
          )}
        </div>
        {localizedLinks?.length > 0 && (
          <ul className="navbar-v2-search-tags">
            <li className="navbar-v2-search-tag">
              <button
                type="submit"
                onClick={clearTags}
                className={cn('navbar-v2-search-tag-btn', {
                  'search-tag-selected': !pickedTags?.length
                })}>
                {translations.allResults}
              </button>
            </li>
            {tags?.map((tag) => {
              return (
                <li key={tag + '_search_tag'} className="navbar-v2-search-tag">
                  <button
                    type="submit"
                    onClick={() => pickTag(tag)}
                    className={cn('navbar-v2-search-tag-btn', {
                      'search-tag-selected': pickedTags?.some((t) => tag === t)
                    })}>
                    {tag}
                  </button>
                </li>
              );
            })}
          </ul>
        )}
      </form>
      {!isInitial.current && !scope?.isLoading && !localizedLinks?.length && (
        <div className="navbar-v2-search-status">{translations.noResults}</div>
      )}
      {!scope?.isLoading && localizedLinks?.length > 0 && (
        <div className="navbar-v2-search-status navbar-v2-search-total">
          {translations.totalResults}
        </div>
      )}
      {!scope?.isLoading && localizedLinks?.length > 0 && (
        <div className="navbar-v2-search-result-container">
          <ul className="navbar-v2-search-results">
            {scope.result?.items?.map((i) => {
              return (
                <li
                  key={i.name + i.url + '_search_result'}
                  className="navbar-v2-search-result-item">
                  <a className="navbar-v2-search-result-link" href={i.url}>
                    {i.name}
                  </a>
                  <div
                    className="navbar-v2-search-result-highlight"
                    dangerouslySetInnerHTML={{ __html: i.highlight }}></div>
                </li>
              );
            })}
          </ul>
          <div className="navbar-v2-search-results-status">
            <div className="navbar-v2-search-results-status-text">
              You have viewed {viewCount} out ot {scope?.result?.total}
              <div className="navbar-v2-search-progress-bar">
                <div
                  className="navbar-v2-search-progress"
                  style={{ width: `${(viewCount / scope.result.total) * 100}%` }}></div>
              </div>
            </div>
            {viewCount < scope.result.total && (
              <div
                role="button"
                tabIndex={0}
                onClick={onLoadMore}
                className="navbar-v2-search-results-status-load">
                {translations.loadMore}
              </div>
            )}
          </div>
        </div>
      )}
      {!localizedLinks?.length && (
        <div className="navbar-v2-search-popular">
          <div className="navbar-v2-search-popular-title">{translations.popularSearch}</div>
          <ul className="navbar-v2-search-popular-list">
            {popularSearches?.map((p) => {
              return (
                <li key={p + '_popular_search_item'} className="navbar-v2-search-popular-item">
                  <a href={queryLinkUrl(p.query)}>#{p.query}</a>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default NavbarSearch;
