class Semver {
  major: number;
  minner: number;
  patch: number;

  constructor(major, minner, patch) {
    this.major = major;
    this.minner = minner;
    this.patch = patch;
  }
  toString() {
    return `${this.major}_${this.minner}_${this.patch}`;
  }
}

class getIOSVersion {
  isIOS() {
    const ua = navigator.userAgent;
    return ua.indexOf('iPhone') > 0 || ua.indexOf('iPad') > 0 || ua.indexOf('iPhone') > 0;
  }

  getIOSSemever() {
    if (this.isIOS()) {
      const extract = navigator.userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);
      return new Semver(extract[1], extract[2], extract[3]);
    } else {
      return null;
    }
  }

  isLowerThenIOSVer(major: number, minner: number, patch: number) {
    const semver = this.getIOSSemever();

    if (semver) {
      return (
        semver?.major < major ||
        (semver?.major == major && semver?.minner < minner) ||
        (semver?.major == major && semver?.minner == minner && semver?.patch < patch)
      );
    }
    return false;
  }
}

export const IOSVersion = new getIOSVersion();
