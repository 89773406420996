import {
  PublicClientApplication,
  SsoSilentRequest,
  AuthenticationResult,
  EventType,
  InteractionType,
  IPublicClientApplication
} from '@azure/msal-browser';
import { MsalProvider, useMsal, MsalAuthenticationTemplate, IMsalContext } from '@azure/msal-react';
import { FC, PropsWithChildren, ReactNode, useCallback, useEffect, useState } from 'react';
import { IDMConfig } from '../types/dto/UserLogin';
import { IOSVersion } from '../helpers/getIOSVersion';
import { useCookies } from 'react-cookie';
import { myZIMLogdinSet } from '../helpers/sharedValues';

let msalInstance: IPublicClientApplication = null;

const MyZimUserAuthenticator = ({
  children,
  idmConfig
}: {
  children: ReactNode;
  idmConfig?: IDMConfig;
}) => {
  const { instance } = useMsal();
  const [cookies] = useCookies(['UMB_PREVIEW']);

  const init = useCallback(async () => {
    if (!idmConfig) return;
    await instance.initialize();
    await instance
      .ssoSilent({
        scopes: idmConfig.knownAuthoritiesToGetToken
          .split(',')
          .map((a) => `https://${a}/tasks/read`),
        account: instance?.getActiveAccount()
      })
      .then((res) => {
        instance.setActiveAccount(res?.account);
        myZIMLogdinSet(true);
      })
      .catch((err) => {
        instance.setActiveAccount(null);
        myZIMLogdinSet(false);
      });
  }, [idmConfig]);

  useEffect(() => {
    if (!IOSVersion.isLowerThenIOSVer(15, 4, 0) && !(cookies?.UMB_PREVIEW == 'preview')) init();
  }, [init]);

  return <>{children}</>;
};

const MyZimUserProvider = ({
  children,
  idmConfig
}: {
  children: ReactNode;
  idmConfig?: IDMConfig;
}) => {
  const [instance, setInstance] = useState(msalInstance);
  const [cookies] = useCookies(['UMB_PREVIEW']);

  useEffect(() => {
    if (
      instance ||
      IOSVersion.isLowerThenIOSVer(15, 4, 0) ||
      cookies?.UMB_PREVIEW == 'preview' ||
      idmConfig == null
    )
      return;

    msalInstance = new PublicClientApplication({
      auth: {
        authority: idmConfig?.authority,
        clientId: idmConfig?.clientId,
        knownAuthorities: idmConfig?.knownAuthorities.split(','),
        redirectUri: idmConfig?.redirectUrl
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true
      }
    });
    msalInstance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
      }
    });
    setInstance(msalInstance);
  }, [idmConfig]);

  if (!instance) return <MyZimUserAuthenticator>{children}</MyZimUserAuthenticator>;
  return (
    <MsalProvider instance={instance}>
      <MyZimUserAuthenticator idmConfig={idmConfig}>{children}</MyZimUserAuthenticator>
    </MsalProvider>
  );
};

export default MyZimUserProvider;
