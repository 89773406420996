import { useLayoutEffect, useState } from 'react';
import { debounce } from '../helpers/debounce';

export const useIsMobileNewUi = () => {
  const [isMobile, setIsMobile] = useState(false);

  useLayoutEffect(() => {
    const resize = () => {
      const screenWidth = window.screen.width;
      const zoomLevel = isDeviceEmulation() ? 1 : window.devicePixelRatio;

      const effectiveWidth = screenWidth / zoomLevel;

      const width =
        window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      setIsMobile(() => {
        return effectiveWidth <= 1280 || width <= 1280;
      });
    };
    resize();
    window.addEventListener('resize', debounce(resize));

    return window.removeEventListener('resize', resize);
  }, []);

  return isMobile;
};

const isDeviceEmulation = () => {
  const userAgent = window.navigator.userAgent;
  const zoomLevel = window.devicePixelRatio;
  return /Mobi|Android/i.test(userAgent) && zoomLevel > 1;
};
