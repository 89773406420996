import { FocusEventHandler, HTMLAttributes, lazy, useRef, useState } from 'react';
import NavbarDropBlock, { NavbarDropBlockMode } from '../common/NavbarDropBlock';
import './styles/NavbarMainSection.scss';
import cn from 'classnames';
import { MenuItem, NewMenuItem } from '../../../../types/dto/MenuModel';
import NavbarDynamicElement from '../elements/NavbarDynamicElement';
import NavbarToggler from '../common/NavbarToggler';
import { useVisibility } from '../hooks/useVisibility';
import WithRtl from '../../../../shared/WithRtl';
const RTL = lazy(() => import('./NavbarMainSectionRtl'));

type Props = {
  popupMode?: NavbarDropBlockMode;
  items?: NewMenuItem[];
  visibility: ReturnType<typeof useVisibility>;
  isRoot?: boolean;
};

const NavbarMainSection = ({
  popupMode = 'popup',
  items = [],
  className,
  visibility,
  isRoot = true
}: HTMLAttributes<HTMLDivElement> & Props) => {
  const { openElem, toggle } = visibility;

  const elemKey = (menuItem: NewMenuItem) => menuItem?.title + '_main_section';
  return (
    <>
      <WithRtl lazyElement={RTL} />

      <ul className={cn('navbar-v2-main', className)}>
        {items?.map((i) => {
          if (!i?.items?.length) {
            return (
              <li
                key={'navbar-main-item' + i.title}
                className={cn('navbar-v2-main-item no-toggle', {
                  'navbar-v2-main-item-not-root': !isRoot
                })}>
                <a
                  key={'top_menu_item_' + i.title}
                  className={cn('navbar-v2-main-link no-toggle', {
                    'navbar-v2-main-link-not-root': !isRoot
                  })}
                  href={i?.url}>
                  {i.title}
                </a>
              </li>
            );
          }
          const elementkey = elemKey(i);

          return (
            <>
              <li
                key={'navbar-main-item' + i.title}
                className={cn('navbar-v2-main-item', {
                  open: openElem == elementkey,
                  'navbar-v2-main-item-not-root': !isRoot
                })}
                onClick={() => toggle(elementkey)}
                onKeyUp={(e) => e.code == 'Enter' && toggle(elementkey)}
                tabIndex={0}>
                <NavbarToggler
                  isOpen={openElem == elementkey}
                  ariaExpanded={openElem === elementkey}
                  isRootDynamic={isRoot}>
                  <span
                    className={cn('navbar-v2-main-link', {
                      'navbar-v2-main-link-not-root': !isRoot
                    })}>
                    {i?.title}
                  </span>
                </NavbarToggler>
              </li>
              {i?.items?.length && (
                <NavbarDropBlock mode={popupMode} isOpen={openElem == elementkey}>
                  <NavbarDynamicElement tabElement={i} viewAllUrl={i?.url} />
                </NavbarDropBlock>
              )}
            </>
          );
        })}
      </ul>
    </>
  );
};

export default NavbarMainSection;
