import { ReactNode, forwardRef, lazy, useCallback, useRef, useState } from 'react';
import NavbarMainSection from '../sections/NavbarMainSection';
import './styles/NavbarMobileMenu.scss';
import NavbarDropBlock from '../common/NavbarDropBlock';
import NavbarCountryLanguage from './NavbarCountryLanguage';
import NavbarUser from './NavbarUser';
import {
  CountryModel,
  GeoLocation,
  LanguageModel,
  MenuItem,
  NewMenuItem,
  TopMenuModel
} from '../../../../types/dto/MenuModel';
import NavbarUserControl from '../controls/NavbarUserControl';
import NavbarLanguageControl from '../controls/NavbarLanguageControl';
import NavbarSearchControl from '../controls/NavbarSearchControl';
import NavbarSearch from './NavbarSearch';
import { ZimSearchConfig } from '../../../../types/dto/ZimSearch';
import NavbarAdditionalSection from '../sections/NavbarAdditionalSection';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { useVisibility } from '../hooks/useVisibility';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import WithRtl from '../../../../shared/WithRtl';
import { useTranslation } from 'react-i18next';
const RTL = lazy(() => import('./NavbarMobileMenuRtl'));

type Props = {
  additionalItems: TopMenuModel[];
  mainItems: NewMenuItem[];
  geoLocation?: GeoLocation;
  searchConfig: ZimSearchConfig;
  languages?: LanguageModel[];
  countries?: CountryModel[];
  visibility: ReturnType<typeof useVisibility>;
};

const NavbarMobileMenu = forwardRef<HTMLDivElement, Props>(
  (
    {
      additionalItems = [],
      mainItems = [],
      geoLocation,
      searchConfig,
      languages = [],
      countries = [],
      visibility
    }: Props,
    ref
  ) => {
    const [isMainShowing, setIsMainShowing] = useState(true);
    const [isControlShowing, setIsControlShowing] = useState(false);
    const [isLanguageOpen, setIsLanguageOpen] = useState(false);
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [control, setControl] = useState(null);
    const mainRef = useRef(null);
    const controllRef = useRef(null);
    const switchRef = isMainShowing ? mainRef : controllRef;

    const { t } = useTranslation();
    const translations = {
      goBack: t('login.GoBack')
    };

    const onCloseControl = useCallback(() => {
      setIsControlShowing(false);
      setIsMainShowing(true);
      setControl(null);
      setIsLanguageOpen(false);
      setIsSearchOpen(false);
    }, []);

    const setCurrentControl = useCallback((node: ReactNode) => {
      setIsMainShowing(false);
      setIsControlShowing(true);
      setControl(node);
    }, []);
    const onLanguageClick = useCallback(() => {
      setCurrentControl(<NavbarCountryLanguage languages={languages} countries={countries} />);
      setIsLanguageOpen(true);
    }, [setCurrentControl]);
    const onSearchClick = useCallback(() => {
      setCurrentControl(<NavbarSearch popularSearches={searchConfig?.popular} />);
      setIsSearchOpen(true);
    }, [setCurrentControl, searchConfig]);
    return (
      <>
        <WithRtl lazyElement={RTL} />
        <div className="navbar-v2-mobile" ref={ref}>
          <SwitchTransition>
            <CSSTransition
              key={isMainShowing ? 'main' : 'control'}
              nodeRef={switchRef}
              timeout={300}
              classNames="navbar-v2-mobile-open">
              <div className="navbar-v2-mobile-wrapper-main" ref={switchRef}>
                {isMainShowing && (
                  <>
                    <div className="navbar-v2-mobile-controls mb-4">
                      <div className="navbar-v2-mobile-language" onClick={onLanguageClick}>
                        <NavbarLanguageControl geoLocation={geoLocation} isOpen={isLanguageOpen} />
                      </div>
                      <div className="navbar-v2-mobile-search" onClick={onSearchClick}>
                        <NavbarSearchControl isDark isOpen={isSearchOpen} />
                      </div>
                    </div>
                    <NavbarMainSection
                      className="navbar-v2-mobile-main-section"
                      popupMode="block"
                      items={mainItems}
                      visibility={visibility}
                    />
                    <NavbarAdditionalSection
                      className="navbar-v2-mobile-additional-section"
                      items={additionalItems}
                      mode="block"
                      visibility={visibility}
                    />
                  </>
                )}
                {isControlShowing && (
                  <>
                    <div className="go-back" onClick={onCloseControl}>
                      <FontAwesomeIcon icon={faArrowLeft} size="xs" />
                      {translations.goBack}
                    </div>
                    {control}
                  </>
                )}
              </div>
            </CSSTransition>
          </SwitchTransition>
        </div>
      </>
    );
  }
);

export default NavbarMobileMenu;
