import { createBrowserHistory } from 'history';
import { QueryParameter } from '../types/dto/QueryParameter';
import { stringExtensions } from './stringExtensions';
import { translationService } from '../services/translationsService';

class RouteHelper {
  private _containerKey = 'containerid';
  private _schedulecLineParams = {
    line: 'line',
    trade: 'trade'
  };
  private _scheduleVesselParams = {
    vesselCode: 'vesselcode',
    voyageNo: 'voyageno',
    direction: 'direction'
  };

  private _billOfLadingCountry = 'country';

  private _trackShipmentParam = 'consnumber';

  private _trackShipmentTypeParam = 'constype';

  private _schedulePortParams = {
    countryCode: 'countrycode',
    portCode: 'portcode',
    direction: 'direction',
    dateFrom: 'datefrom',
    weeksAhead: 'weeksahead',
    transitrange: 'transitrange',
    directLine: 'directLine',
    onetransshipment: 'onetransshipment',
    twoormore: 'twoormore'
  };

  private _demurrageCalculatorParams = {
    countryCode: 'countryCode',
    portCode: 'portCode',
    containerType: 'containerType',
    direction: 'direction',
    startDate: 'startDate',
    endDate: 'endDate',
    startDateInPort: 'startDateInPort',
    endDateInPort: 'endDateInPort',
    startDateOutPort: 'startDateOutPort',
    endDateOutPort: 'endDateOutPort',
    freeDays: 'freeDays',
    freeDaysAdditional: 'freeDaysAdditional',
    tariff: 'tariff',
    tariffAdditional: 'tariffAdditional',
    inOutInd: 'inOutInd',
    inOutIndAdditional: 'inOutIndAdditional',
    prgInd: 'prgInd',
    prgIndAdditional: 'prgIndAdditional',
    requestId: 'requestId',
    requestIdAdditional: 'requestIdAdditional'
  };

  private _contactUsParam = 'branch';
  private _trackShipmentMultiParam = 'consnumbers';

  private _history = createBrowserHistory({ window });

  get history() {
    return this._history;
  }

  globalSearchUrl(query: string) {
    const lang = translationService.getLanguage();

    const langSegment = lang === 'en' ? '' : `/${lang}`;

    return `${langSegment}/search?q=${query}`;
  }
  onGlobalSearch = (query: string) => {
    if (stringExtensions.isEmptyOrSpaces(query)) {
      return;
    }

    this._history.replace('./');
    this._history.push(this.globalSearchUrl(query));
    this._history.go(0);
  };

  getQueryVariable = (variable: string) => {
    const urlParams = new URLSearchParams(this._history.location.search);
    const code = urlParams.get(variable);
    return code;
  };

  setQueryParameters = <T extends string>(queryParameters: QueryParameter<T>[]) => {
    if (!queryParameters?.length) {
      // This logic is needed in case we want to clear search params and perform default routing
      this._history.push(this._history.location.pathname);

      return;
    }

    const urlParams = new URLSearchParams(this._history.location.search);

    // CRUD for current query params based on provided value
    queryParameters.forEach(({ key, value }) => {
      if (value === '') {
        urlParams.delete(key);
        return;
      }

      if (!urlParams.has(key)) {
        urlParams.append(key, value);
        return;
      }

      urlParams.set(key, value);
    });

    const currentQueryNames = Object.keys(Object.fromEntries(urlParams.entries()));

    // Cleaning url query params, that were not mentioned in function props
    currentQueryNames.forEach(
      (name) => !queryParameters.find((query) => query.key === name) && urlParams.delete(name)
    );

    const finalParams = urlParams.toString();
    const newurl =
      this._history.location.pathname.replace(/\/\//g, '/') +
      (finalParams ? '?' + finalParams : '');

    this._history.push(newurl);
  };

  get containerKey() {
    return this._containerKey;
  }

  get scheduleLineParams() {
    return this._schedulecLineParams;
  }

  get scheduleVesselParams() {
    return this._scheduleVesselParams;
  }

  get billOfLadingCountry() {
    return this._billOfLadingCountry;
  }

  get trackShipmentParam() {
    return this._trackShipmentParam;
  }

  get schedulePortParams() {
    return this._schedulePortParams;
  }

  get trackShipmentTypeParam() {
    return this._trackShipmentTypeParam;
  }

  get demurrageCalculatorParameters() {
    return this._demurrageCalculatorParams;
  }

  get contactUsParam() {
    return this._contactUsParam;
  }

  get trackShipmentMultiParam() {
    return this._trackShipmentMultiParam;
  }
}

export const routeHelper = new RouteHelper();
