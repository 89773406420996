import { HTMLAttributes, ReactNode, lazy, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import './styles/NavbarDropBlock.scss';
import cn from 'classnames';
import WithRtl from '../../../../shared/WithRtl';

const RTL = lazy(() => import('./NavbarDropBlock.Rtl'));

export type NavbarDropBlockMode = 'popup' | 'block';

const NavbarDropBlock = ({
  children,
  mode = 'popup',
  isOpen,
  left = true,
  right = true,
  contentClassName,
  className,
  timeout = 300
}: Omit<HTMLAttributes<HTMLDivElement>, 'title'> & {
  children: ReactNode;
  mode?: NavbarDropBlockMode;
  isOpen: boolean;
  left?: boolean;
  right?: boolean;
  contentClassName?: string;
  timeout?: number;
}) => {
  const contentRef = useRef(null);

  return (
    <>
      <WithRtl lazyElement={RTL} />
      <CSSTransition
        in={isOpen}
        nodeRef={contentRef}
        timeout={timeout}
        classNames={cn('navbar-v2-dropblock-content-open', className)}
        unmountOnExit>
        <div
          ref={contentRef}
          className={cn('navbar-v2-dropblock-content', contentClassName, {
            'navbar-v2-dropblock-popup': mode === 'popup',
            'navbar-v2-dropblock-block': mode === 'block',
            'navbar-v2-dropblock-left': left,
            'navbar-v2-dropblock-right': right
          })}>
          {children}
        </div>
      </CSSTransition>
    </>
  );
};

export default NavbarDropBlock;
