import searchLogo from '../../../../assets/images/icon-new-search.svg';
import darkSearchLogo from '../../../../assets/images/icon-new-search-dark.svg';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

const NavbarSearchControl = ({
  isDark = false,
  isOpen,
  onClick
}: {
  isDark?: boolean;
  isOpen: boolean;
  onClick?: MouseEventHandler<HTMLElement>;
}) => {
  const { t } = useTranslation();

  const translations = {
    searchLogoAlt: t('accessibility.SearchLogo')
  };
  return (
    <img
      src={isDark ? darkSearchLogo : searchLogo}
      onClick={onClick}
      height="28"
      width="28"
      alt={translations.searchLogoAlt}
      role="button"
      aria-expanded={isOpen}
      tabIndex={0}
    />
  );
};

export default NavbarSearchControl;
