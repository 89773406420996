import { useEffect, useMemo, useState } from 'react';
import { env } from '../../environments/environment';
import { translationService } from '../../services/translationsService';
import { useCookies } from 'react-cookie';
import { useGlobalLoader } from '../../hooks/useGlobalLoader';
import { appHelper } from '../../helpers/appHelper';
import {
  ContactCountryModel,
  CountryModel,
  GeoLocation,
  LanguageModel,
  MenuItem,
  MenuModel,
  MenuMyZim,
  TopMenuModel
} from '../../types/dto/MenuModel';
import { getData } from '../../services/crud';
import { useIsMobile } from '../../hooks/useIsMobile';
import { navBarService } from '../../services/navBarService';
import { useTranslation } from 'react-i18next';
import useNavbarUiVersion from '../../hooks/useNavbarUiVersion';
const isInvestors = false;
export const useNavbar = ({
  countryRedirects,
  defaultValues
}: {
  countryRedirects?: { CountryCode: string; Prefix: string; LanguageCode: string }[];
  defaultValues?: any;
}) => {
  const [MainMenu, setMainMenu]: any = useState([]);
  const [TopMenu, setTopMenu]: any = useState([]);
  const [ContactTab, setContactTab] = useState<ContactCountryModel>(null);
  const [Languages, setLanguages] = useState<LanguageModel[]>([]);
  const [Countries, setCountries] = useState<CountryModel[]>([]);
  const [MyZim, setMyZim] = useState<MenuMyZim>();
  const [cookies, setCookie] = useCookies(['language', 'country', 'countryName', 'ui-version']);
  const [culture] = useState(translationService.getCulture());
  const [geoLocation, setGeoLocation] = useState(appHelper.getDefaultScope<GeoLocation>());
  const [dataLoaded, setDataLoaded] = useState(false);
  const { start: startLoader, finish: finishLoader } = useGlobalLoader();

  const { version } = useNavbarUiVersion();

  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const translations = useMemo(() => {
    return {
      commonError: t('common')
    };
  }, [t]);

  const cookieDomain = useMemo(() => {
    const domain = appHelper.getDomainWithoutSubdomain().split('/')[0];
    if (domain === 'localhost') {
      return domain;
    }

    return `.${domain}`;
  }, []);

  const initCookies = () => {
    if (!cookies.country) {
      setCookie('country', 'US', {
        path: '/',
        expires: appHelper.cookiesExpiryDate(),
        secure: true,
        domain: cookieDomain
      });
      setCookie('countryName', 'U.S.A.', {
        path: '/',
        expires: appHelper.cookiesExpiryDate(),
        secure: true,
        domain: cookieDomain
      });
    }
    if (!cookies.language) {
      setCookie('language', 'en-US', {
        path: '/',
        expires: appHelper.cookiesExpiryDate(),
        secure: true,
        domain: cookieDomain
      });
    }
    if (cookies.language !== culture) {
      setCookie('language', culture, {
        path: '/',
        expires: appHelper.cookiesExpiryDate(),
        secure: true,
        domain: cookieDomain
      });
    }
  };

  const getGeoLocation = async () => {
    setGeoLocation((prev) => {
      return { ...prev, isLoading: true };
    });

    let errorMessage = undefined;
    let result: GeoLocation = null;

    try {
      const apiResult = await navBarService.getGeoLocation();
      result = apiResult;
    } catch (error) {
      errorMessage = `${translations.commonError}`;
    }

    const newGeoLocation = {
      ...geoLocation,
      isLoading: false,
      errorMessage,
      result,
      isResponse: true
    };
    setGeoLocation(newGeoLocation);

    return newGeoLocation;
  };
  const checkForRedirections = async (
    geoLocationPromise: ReturnType<typeof getGeoLocation>,
    result: any
  ) => {
    const currentGeoLocation = await geoLocationPromise;
    const currentRedirect = currentGeoLocation.isResponse
      ? countryRedirects?.find((c) => c.CountryCode == currentGeoLocation.result?.countryIsoCode)
      : null;

    if (currentRedirect && !cookies.language) {
      const currentPrefix = getCurrentPrefix(result?.languages);

      if (currentRedirect.Prefix !== currentPrefix) {
        setCookie('language', currentRedirect.LanguageCode, {
          path: '/',
          expires: appHelper.cookiesExpiryDate(),
          secure: true,
          domain: cookieDomain
        });
        window.location.href = `${window.location.origin}${getLangUrlPath(
          currentRedirect.Prefix,
          result?.languages
        )}`;
        return;
      }
    }
  };

  const getMenu = async () => {
    startLoader();

    try {
      const geoLocationPromise = getGeoLocation();

      const result =
        defaultValues ??
        (await getData(
          `${
            version === 'v2'
              ? env.dynamic['REACT_APP_CONNECT_TO_APIM']
                ? env.fixed.apimEndpoints.getNewMenu
                : env.fixed.navbar.getNewMenu
              : env.dynamic['REACT_APP_CONNECT_TO_APIM']
              ? env.fixed.apimEndpoints.getMenu
              : env.fixed.navbar.getMenu
          }/${culture ? culture : cookies.language ? cookies.language : 'en-US'}/${
            cookies.country ? cookies.country : 'US'
          }/${isInvestors}?newUi=${false}`
        ));

      checkForRedirections(geoLocationPromise, result);

      setTopMenu(result?.topMenu);
      setMainMenu(result?.mainMenu);
      setLanguages(result?.languages);
      setContactTab(result?.contactCountry);
      setCountries(result?.countries);
      setMyZim(result?.myZim);
      setDataLoaded(true);
      finishLoader();
    } catch {
      finishLoader();
    }
    initCookies();
  };
  const getCurrentPrefix = (languages: any) => {
    const currentLangCulture = translationService.getDataCultureLanguage();

    return languages.find((lang) => lang.isoCode === currentLangCulture).prefix;
  };
  const getLangUrlPath = (prefix: string, languages: any) => {
    if (!languages?.length) return '';
    const currentRoute = window.location.pathname + window.location.search;

    const newLangPrefix = prefix === '' ? '' : `/${prefix}`;
    const currentPrefix = getCurrentPrefix(languages);

    if (!currentPrefix) return newLangPrefix + currentRoute;

    return currentRoute.replace(`/${currentPrefix}`, newLangPrefix);
  };

  useEffect(() => {
    getMenu();
  }, []);

  return {
    MainMenu,
    TopMenu,
    ContactTab,
    Countries,
    Languages,
    MyZim,
    isMobile,
    geoLocation,
    dataLoaded
  };
};
