import { lazy, useState } from 'react';
import './styles/NavbarDynamicElement.scss';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { MenuItem, NewMenuItem } from '../../../../types/dto/MenuModel';
import { useTranslation } from 'react-i18next';
import WithRtl from '../../../../shared/WithRtl';
const RTL = lazy(() => import('./NavbarDynamicElementRtl'));

type Props = {
  tabElement?: NewMenuItem;
  viewAllUrl?: string;
};

const NavbarDynamicElement = ({ tabElement = null, viewAllUrl = null }: Props) => {
  const { t } = useTranslation();

  const translations = {
    viewAll: t('navbar.INeed.ViewAll'),
    extra: t('navbar.INeed.Extra')
  };

  const isDescriptionIncluded = tabElement?.items?.some((item) => {
    if (item?.description) {
      return true;
    }
    return false;
  });

  const classNameExtras = () => {
    if (tabElement?.banner && tabElement?.extras && tabElement?.extras?.length)
      return 'with-additional-area-extra';
    if (tabElement?.banner || (tabElement?.extras && tabElement?.extras?.length))
      return 'with-additional-area';
    return '';
  };

  return (
    <>
      <WithRtl lazyElement={RTL} />
      <div className={classnames(`navbar-v2-dynamic-element ${classNameExtras()}`)}>
        <div
          className={classnames(`grid-items ${classNameExtras()}`, {
            'with-description': isDescriptionIncluded
          })}>
          {tabElement?.items?.map((subMenu) =>
            subMenu != null ? (
              <div className="item" key={'navbar-subMenu-item' + subMenu?.title}>
                <a className="title" href={subMenu?.url} target={subMenu?.target}>
                  {subMenu?.title}
                </a>
                <div className="description">{subMenu?.description}</div>
              </div>
            ) : (
              ''
            )
          )}
        </div>
        {tabElement?.extras && !!tabElement?.extras?.length && (
          <div className="additional-area-extra">
            <div className="extra-title">{translations.extra}</div>
            <div className={classnames(`grid-items`)}>
              {tabElement?.extras?.map((extra) =>
                extra != null ? (
                  <div className="item" key={'navbar-submenu-extra-item' + extra?.title}>
                    {' '}
                    <a className="title" href={extra?.url} target={extra?.target}>
                      {extra?.title}
                    </a>
                  </div>
                ) : (
                  ''
                )
              )}
            </div>
          </div>
        )}
        {tabElement?.banner && (
          <div className="additional-area">
            <a
              href={tabElement?.banner?.bannerLinkUrl}
              target={tabElement?.banner?.bannerLinkTarget}>
              <img className="banner-image" src={tabElement.banner?.bannerImageUrl} />
              <img className="banner-image-mobile" src={tabElement.banner?.bannerImageMobileUrl} />
            </a>
          </div>
        )}
        {viewAllUrl && (
          <a className="list-link mobile" href={viewAllUrl}>
            {`${translations.viewAll} ${tabElement?.title}`}
            <FontAwesomeIcon icon={faArrowRight} size="xs" />
          </a>
        )}
        {viewAllUrl && (
          <a className="list-link desktop" href={viewAllUrl}>
            {tabElement?.viewAllTitle && tabElement?.viewAllTitle != ''
              ? tabElement?.viewAllTitle
              : translations.viewAll}
            <FontAwesomeIcon icon={faArrowRight} size="xs" />
          </a>
        )}
      </div>
    </>
  );
};

export default NavbarDynamicElement;
