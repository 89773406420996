import { useNavbarStore } from '../navbarContext';
import { useState } from 'react';
import cn from 'classnames';
import './styles/NavbarUser.scss';
import { UserLogin } from '../../../../types/dto/UserLogin';
import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';
import { MenuMyZim } from '../../../../types/dto/MenuModel';
import { useCookies } from 'react-cookie';

const NavbarUserTab = ({
  login,
  isLoggedIn,
  myZim
}: {
  login: UserLogin;
  isLoggedIn?: boolean;
  myZim?: MenuMyZim;
}) => {
  const [cookies, setCookie] = useCookies(['langPath']);

  const { t } = useTranslation();
  const translations = {
    goTo: t('login.GoTo'),
    goToLabel: t('login.GoToLabel', {
      replace: {
        url: login?.loginUrl
      }
    }),
    loginTo: t('login.LoginTo'),
    logoutLabel: t('login.LogoutLabel', {
      replace: {
        serviceName: login?.type
      }
    }),
    logout: t('login.Logout'),
    register: t('login.Register'),
    forgotPassword: t('login.ForgotPassword')
  };
  const isMyZim = login?.type === 'myZIM';
  if (!login) return <></>;
  return (
    <div key={login.logo + 'user_logo'} className="navbar-v2-user-login-option">
      <div className="login-description">{login?.description}</div>
      {!isLoggedIn && isMyZim && (
        <a className="forgot-password" href={myZim?.forgotPassword}>
          {translations.forgotPassword}
        </a>
      )}
      <a
        className="user-login"
        href={login?.loginUrl}
        target="_blank"
        aria-label={translations.goToLabel}
        rel="noreferrer">
        <button className="btn btn-primary">{translations.goTo}</button>
      </a>
      {!isLoggedIn && isMyZim && (
        <a className="register" href={myZim?.register}>
          {translations.register}
        </a>
      )}

      {login.logoutUrl && isLoggedIn && (
        <a
          className="user-logout"
          href={login?.logoutUrl}
          target="_blank"
          aria-label={translations.logoutLabel}
          rel="noreferrer">
          {translations.logout}
        </a>
      )}
    </div>
  );
};

const NavbarUser = ({ myZim }: { myZim?: MenuMyZim }) => {
  const [logins] = useNavbarStore((store) => store.logins);
  const [currentTab, setCurrentTab] = useState(0);
  const onTabSelect = (index: number) => setCurrentTab(() => index);
  const { accounts, instance } = useMsal();
  const { t } = useTranslation();
  const translations = {
    loginTo: t('login.LoginTo')
  };
  return (
    <div className="navbar-v2-user-login">
      <header>{translations.loginTo}</header>
      <ul className="navbar-v2-user-login-tabs" role="tablist">
        {logins?.map((login, i) => {
          return (
            <li
              key={login.loginUrl + '_navbar_user_tab'}
              role="tab"
              className={cn(
                {
                  'is-active': currentTab === i
                },
                'navbar-v2-user-login-tab'
              )}>
              <img
                src={
                  process.env.NODE_ENV === 'development'
                    ? process.env.REACT_APP_DOMAIN_URL + login.logo
                    : login.logo
                }
                role="button"
                tabIndex={0}
                onClick={() => onTabSelect(i)}
                onKeyUp={(event) => event.code === 'Enter' && onTabSelect(i)}
              />
            </li>
          );
        })}
      </ul>
      <NavbarUserTab
        login={logins?.[currentTab]}
        isLoggedIn={logins?.[currentTab]?.type === 'myZIM' && !!instance?.getActiveAccount()}
        myZim={myZim}
      />
    </div>
  );
};

export default NavbarUser;
