import { MouseEventHandler, ReactNode, lazy, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './styles/NavbarToggler.scss';
import { NAVBAR_ANIMATION_DURATION } from '../navbarConstants';
import cn from 'classnames';
import WithRtl from '../../../../shared/WithRtl';
const RTL = lazy(() => import('./NavbarTogglerRtl'));

const NavbarToggler = ({
  children,
  isOpen,
  className,
  onClick,
  hasChildren = true,
  isRoot = true,
  isRootDynamic = true,
  ariaExpanded = false
}: {
  children: ReactNode;
  isOpen: boolean;
  className?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  hasChildren?: boolean;
  isRoot?: boolean;
  isRootDynamic?: boolean;
  ariaExpanded?: boolean;
}) => {
  const togglerRef = useRef(null);
  return (
    <>
      <WithRtl lazyElement={RTL} />
      <div
        ref={togglerRef}
        onClick={onClick}
        role="button"
        className={cn('navbar-v2-toggler', className, {
          'navbar-v2-toggler-open': isOpen
        })}
        aria-expanded={ariaExpanded}>
        {children}
        {hasChildren && (
          <FontAwesomeIcon
            icon={faChevronDown}
            className={cn('navbar-v2-toggler-chevron', className, {
              'navbar-v2-toggler-chevron-not-root': !isRoot,
              'navbar-v2-toggler-chevron-dynamic-not-root': !isRootDynamic
            })}
          />
        )}
      </div>
    </>
  );
};

export default NavbarToggler;
