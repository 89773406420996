import { MutableRefObject, useCallback, useLayoutEffect } from 'react';

const useOutsideClick = <T extends HTMLElement>(
  wrapperRef: MutableRefObject<T>,
  handler: () => void
) => {
  const handleClickOutside = useCallback(
    function (this: Document, event: MouseEvent) {
      if (
        (wrapperRef?.current?.contains(event.target as Node) ?? false) ||
        (event.target as HTMLElement).dataset.forceClick
      ) {
        return;
      }

      handler();
    },
    [wrapperRef, handler]
  );

  useLayoutEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [handleClickOutside]);
};

export { useOutsideClick };
