import { FocusEventHandler, useRef, useState } from 'react';
import { useIsMobileNewUi } from '../../../../hooks/useIsMobileNewUi';
import { appHelper } from '../../../../helpers/appHelper';

const useVisibility = <T,>(delay?: number) => {
  const isMobile = useIsMobileNewUi();
  const timeoutDelay = useRef(delay ?? 300);

  const openTimeout = useRef(null);
  const closeTimeout = useRef(null);

  const [openElem, setOpenElem] = useState<T | null>(null);

  const open = (elem: T) => {
    openTimeout.current && clearTimeout(openTimeout.current);
    if (openElem === elem) {
      closeTimeout.current && clearTimeout(closeTimeout.current);
      closeTimeout.current = null;
    }
    if (openElem !== elem) {
      setOpenElem(() => elem);
      appHelper.greyOverlayToggle(true);
    }
  };

  const close = () => {
    openTimeout.current && clearTimeout(openTimeout.current);
    if (closeTimeout.current) {
      return;
    }
    setOpenElem(() => null);
    closeTimeout.current = null;
    appHelper.greyOverlayToggle(false);
  };

  const onBlur: FocusEventHandler<HTMLElement> = (event) => {
    if (event.currentTarget.contains(event.relatedTarget)) {
      return;
    }

    close();
  };

  const onMouseEnter = (elem: T) => {
    if (isMobile) {
      return;
    }

    open(elem);
  };

  const onMouseLeave = () => {
    if (isMobile) {
      return;
    }

    close();
  };

  const onFocus = (elem: T) => {
    if (isMobile) {
      return;
    }

    open(elem);
  };

  const toggle = (elem: T) => {
    if (openElem === null) {
      open(elem);
      return;
    }

    if (elem === openElem) {
      close();
      return;
    }

    open(elem);
  };

  return {
    openElem,
    onBlur,
    onFocus,
    onMouseEnter,
    onMouseLeave,
    toggle
  };
};

export { useVisibility };
