import { FC, forwardRef, MutableRefObject } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import Typeahead from 'react-bootstrap-typeahead/types/core/Typeahead';
import useTypeaheadDropup, { ZimTypeaheadProps } from '../../hooks/useTypeaheadDropup';

type ZimAsyncTypeahead = ReturnType<typeof AsyncTypeahead>;

const ZimAsyncTypeahead: FC<ZimTypeaheadProps<typeof AsyncTypeahead>> = forwardRef(
  (props, ref: MutableRefObject<Typeahead>) => {
    const { dropup, typeaheadRef, newProps } = useTypeaheadDropup<typeof AsyncTypeahead>(
      props,
      ref
    );

    return <AsyncTypeahead {...newProps} dropup={dropup} ref={typeaheadRef} />;
  }
);

export default ZimAsyncTypeahead;
