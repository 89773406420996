import { env } from '../environments/environment';
import { ZimSearchConfig, ZimSearchResult } from '../types/dto/ZimSearch';
import { getData } from './crud';

class ZimSearchService {
  async search(
    query: string,
    page: string,
    take: string,
    sortBy?: string,
    sortDir?: string,
    tags?: string
  ) {
    return await getData<ZimSearchResult>(
      `${env.fixed.zimSearch.search}?page=${page}&q=${query}&take=${take}&sortBy=${sortBy}&sortDir=${sortDir}&tags=${tags}`,
      'zim-search'
    );
  }

  async config() {
    try {
      return await getData<ZimSearchConfig>(
        env.dynamic['REACT_APP_CONNECT_TO_APIM']
          ? env.fixed.apimEndpoints.zimSearchConfig
          : env.fixed.zimSearch.config
      );
    } catch {
      return {
        tags: [],
        popular: []
      };
    }
  }
}

export const zimSearchService = new ZimSearchService();
