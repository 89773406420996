import { useEffect, useState } from 'react';
import './Carousel.scss';
import { appHelper } from '../../helpers/appHelper';

type props = {
  children: JSX.Element[];
  show?: number;
  infiniteLoop?: boolean;
};

const Carousel = ({ children, show, infiniteLoop }: props) => {
  const [currentIndex, setCurrentIndex] = useState(infiniteLoop ? show : 0);
  const [length, setLength] = useState(children.length);
  const [isRepeating, setIsRepeating] = useState(infiniteLoop && children.length > show);
  const [transitionEnabled, setTransitionEnabled] = useState(true);
  const [touchPosition, setTouchPosition] = useState(null);
  const [isRtl] = useState(appHelper.isRtl);

  useEffect(() => {
    setLength(children.length);
    setIsRepeating(infiniteLoop && children.length > show);
  }, [children, infiniteLoop, show]);

  useEffect(() => {
    if (isRepeating) {
      if (currentIndex === show || currentIndex === length) {
        setTransitionEnabled(true);
      }
    }
  }, [currentIndex, isRepeating, show, length]);

  const next = () => {
    if (isRtl) {
      if (isRepeating || currentIndex > 0) {
        setCurrentIndex((prevState) => prevState - 1);
      }
    } else {
      if (isRepeating || currentIndex < length - show) {
        setCurrentIndex((prevState) => prevState + 1);
      }
    }
  };

  const prev = () => {
    if (isRtl) {
      if (isRepeating || currentIndex < length - show) {
        setCurrentIndex((prevState) => prevState + 1);
      }
    } else {
      if (isRepeating || currentIndex > 0) {
        setCurrentIndex((prevState) => prevState - 1);
      }
    }
  };

  const handleTouchStart = (e) => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = (e) => {
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 5) {
      next();
    }

    if (diff < -5) {
      prev();
    }

    setTouchPosition(null);
  };

  const handleTransitionEnd = () => {
    if (isRepeating) {
      if (currentIndex === 0) {
        setTransitionEnabled(false);
        setCurrentIndex(length);
      } else if (currentIndex === length + show) {
        setTransitionEnabled(false);
        setCurrentIndex(show);
      }
    }
  };

  const renderExtraPrev = () => {
    const output = [];
    for (let index = 0; index < show; index++) {
      output.push(children[length - 1 - index]);
    }
    output.reverse();
    return output;
  };

  const renderExtraNext = () => {
    const output = [];
    for (let index = 0; index < show; index++) {
      output.push(children[index]);
    }
    return output;
  };

  return (
    <div className="carousel-container">
      <div className="carousel-wrapper">
        {length > show && (
          <button
            className="left-arrow"
            onClick={prev}
            disabled={!isRepeating && (isRtl ? currentIndex >= length - show : currentIndex <= 0)}>
            &lt;
          </button>
        )}
        <div
          className="carousel-content-wrapper"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}>
          <div
            className={`carousel-content show-${show} ${show >= length ? 'flex-center' : ''}`}
            style={{
              transform: `translateX(${isRtl ? '' : '-'}${currentIndex * (100 / show)}%)`,
              transition: !transitionEnabled ? 'none' : undefined
            }}
            onTransitionEnd={() => handleTransitionEnd()}>
            {length > show && isRepeating && renderExtraPrev()}
            {children}
            {length > show && isRepeating && renderExtraNext()}
          </div>
        </div>
        {length > show && (
          <button
            className="right-arrow"
            onClick={next}
            disabled={!isRepeating && (isRtl ? currentIndex <= 0 : currentIndex >= length - show)}>
            &gt;
          </button>
        )}
      </div>
    </div>
  );
};

export default Carousel;
