import NavbarDropBlock from '../common/NavbarDropBlock';
import NavbarCountryLanguage from '../elements/NavbarCountryLanguage';
import NavbarINeed from '../elements/NavbarINeed';
import NavbarSearch from '../elements/NavbarSearch';
import NavbarUser from '../elements/NavbarUser';
import NavbarMainSection from './NavbarMainSection';
import './styles/NavbarCoreSection.scss';
import { forwardRef, lazy, useLayoutEffect, useRef } from 'react';
import {
  ContactCountryModel,
  CountryModel,
  GeoLocation,
  LanguageModel,
  MenuMyZim,
  NewMenuItem
} from '../../../../types/dto/MenuModel';
import NavbarToggler from '../common/NavbarToggler';
import NavbarUserControl from '../controls/NavbarUserControl';
import NavbarSearchControl from '../controls/NavbarSearchControl';
import NavbarLanguageControl from '../controls/NavbarLanguageControl';
import { ZimSearchConfig } from '../../../../types/dto/ZimSearch';
import { useTranslation } from 'react-i18next';
import { useVisibility } from '../hooks/useVisibility';
import cn from 'classnames';
import WithRtl from '../../../../shared/WithRtl';
import { IDMConfig } from '../../../../types/dto/UserLogin';
import { useIsMobileShared } from '../../../../hooks/useIsMobileShared';
const RTL = lazy(() => import('./NavbarCoreSectionRtl'));

type NavbarCoreSectionProps = {
  mainItems?: NewMenuItem[];
  languages?: LanguageModel[];
  countries?: CountryModel[];
  geoLocation?: GeoLocation;
  searchConfig: ZimSearchConfig;
  className?: string;
  iNeedTab?: NewMenuItem;
  contactTab?: ContactCountryModel;
  visibility: ReturnType<typeof useVisibility>;
  isRoot?: boolean;
  idmConfig?: IDMConfig;
  myZim?: MenuMyZim;
};

const NavbarCoreSection = forwardRef<HTMLDivElement, NavbarCoreSectionProps>(
  (
    {
      mainItems = [],
      languages = [],
      countries = [],
      geoLocation,
      searchConfig,
      iNeedTab = null,
      className,
      contactTab,
      visibility,
      isRoot = true,
      idmConfig = null,
      myZim = null
    },
    ref
  ) => {
    //const fallbackRef = useRef<HTMLDivElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);
    useLayoutEffect(() => {
      if (!ref) return;
      if (typeof ref == 'function') {
        ref(wrapperRef.current);
        return;
      }
      wrapperRef.current = ref.current;
    }, [ref]);
    const { t } = useTranslation();
    const isMobile = useIsMobileShared(992);

    const { openElem, toggle } = visibility;

    const translations = {
      iNeed: t('navbar.INeed')
    };

    return (
      <>
        <WithRtl lazyElement={RTL} />
        <div className={'navbar-v2-core'} ref={wrapperRef}>
          <div
            className={cn('navbar-v2-core-ineed-wrapper navbar-v2-core-item', {
              open: openElem === 'ineed'
            })}>
            <button
              className={cn('navbar-v2-core-ineed', className, {
                'navbar-v2-core-ineed-not-root': !isRoot
              })}
              aria-expanded={openElem === 'ineed'}
              onClick={() => toggle('ineed')}
              onKeyUp={(e) => e.code === ' Enter' && toggle('ineed')}>
              <NavbarToggler
                isOpen={openElem === 'ineed'}
                className={cn('navbar-v2-core-ineed-toggler', className, {
                  'navbar-v2-core-ineed-toggler-not-root': !isRoot
                })}
                isRoot={isRoot}>
                {translations.iNeed}
              </NavbarToggler>
            </button>
            <NavbarDropBlock
              isOpen={openElem === 'ineed'}
              contentClassName="navbar-v2-core-mobile-control navbar-v2-dropblock-content-ineed">
              <NavbarINeed iNeedData={iNeedTab} contactTab={contactTab} />
            </NavbarDropBlock>
          </div>

          <NavbarMainSection
            className="navbar-v2-core-item"
            items={mainItems}
            visibility={visibility}
            isRoot={isRoot}
          />

          <ul className="navbar-v2-core-controlls navbar-v2-core-item" role="tablist">
            <li
              className={cn('navbar-v2-core-control search-button', {
                open: openElem === 'search'
              })}
              role="tab"
              tabIndex={0}
              onClick={() => toggle('search')}
              aria-expanded={openElem === 'search'}>
              <NavbarSearchControl
                isOpen={openElem === 'search'}
                onClick={() => toggle('search')}
                isDark={!isRoot}
              />
            </li>
            <NavbarDropBlock isOpen={openElem === 'search'}>
              <NavbarSearch popularSearches={searchConfig?.popular} />
            </NavbarDropBlock>

            <div className="language-country-button">
              <li
                className={cn('navbar-v2-core-control', {
                  open: openElem === 'locale'
                })}
                role="tab"
                tabIndex={0}
                onClick={() => toggle('locale')}
                aria-expanded={openElem === 'locale'}>
                <NavbarLanguageControl
                  geoLocation={geoLocation}
                  isRoot={isRoot}
                  isOpen={openElem === 'locale'}
                />
              </li>
              <NavbarDropBlock
                isOpen={openElem === 'locale'}
                left={isMobile}
                contentClassName="navbar-v2-core-content-countryLanguage">
                <NavbarCountryLanguage
                  languages={languages}
                  countries={countries}
                  geoLocation={geoLocation}
                  isRoot={isRoot}
                />
              </NavbarDropBlock>
            </div>

            <>
              <li
                className={cn('navbar-v2-core-control user', {
                  open: openElem === 'user'
                })}
                role="tab"
                tabIndex={0}
                onClick={() => toggle('user')}
                aria-expanded={openElem === 'user'}>
                <NavbarUserControl
                  isOpen={openElem === 'user'}
                  isRoot={isRoot}
                  onClick={() => toggle('user')}
                  idmConfig={idmConfig}
                  isMobile={isMobile}
                  myZim={myZim}
                />
              </li>
              <NavbarDropBlock
                isOpen={openElem === 'user'}
                left={isMobile}
                contentClassName="navbar-v2-core-mobile-control navbar-v2-core-control-user">
                <NavbarUser myZim={myZim} />
              </NavbarDropBlock>
            </>
          </ul>
        </div>
      </>
    );
  }
);

export default NavbarCoreSection;
