import { MouseEventHandler, ReactNode, useRef } from 'react';
import cn from 'classnames';
import './styles/NavbarBurger.scss';

const NavbarBurger = ({
  isOpen,
  onClick,
  children,
  isRoot = true
}: {
  isOpen: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  children?: ReactNode;
  isRoot?: boolean;
}) => {
  return (
    <>
      <span className={cn('navbar-v2-burger', { 'burger-open': isOpen })} onClick={onClick}>
        <span
          className={cn('navbar-v2-burger-line burger-line-top', {
            'navbar-v2-burger-line-not-root': !isRoot
          })}></span>
        <span
          className={cn('navbar-v2-burger-line burger-line-middle', {
            'navbar-v2-burger-line-not-root': !isRoot
          })}></span>
        <span
          className={cn('navbar-v2-burger-line burger-line-bottom', {
            'navbar-v2-burger-line-not-root': !isRoot
          })}></span>
      </span>
      {children}
    </>
  );
};

export default NavbarBurger;
