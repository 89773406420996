import { env } from '../environments/environment';
import { Banners } from '../types/dto/BannerPopup';
import { GeoLocation } from '../types/dto/MenuModel';
import { getData } from './crud';

class NavBarService {
  getGeoLocation = async (): Promise<GeoLocation> => {
    const data = await getData<GeoLocation>(
      env.dynamic['REACT_APP_CONNECT_TO_APIM']
        ? `${env.fixed.apimEndpoints.getGeoLocation}`
        : `/nc/${env.fixed.navbar.getGeoLocation}`
    );
    return data;
  };
}

export const navBarService = new NavBarService();
