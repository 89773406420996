import { useEffect, useState } from 'react';
import { ZimSearchConfig } from '../../types/dto/ZimSearch';
import { zimSearchService } from '../../services/zimSearch';

export const useSearchConfig = () => {
  const [searchConfig, setSearchConfig] = useState<ZimSearchConfig>();

  const loadConfig = async () => {
    const data = await zimSearchService.config();
    setSearchConfig(data);
  };

  useEffect(() => {
    loadConfig();
  }, []);

  return { searchConfig };
};
